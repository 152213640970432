// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-buckmemo-about-tsx": () => import("./../../../src/pages/buckmemo/about.tsx" /* webpackChunkName: "component---src-pages-buckmemo-about-tsx" */),
  "component---src-pages-buckmemo-index-tsx": () => import("./../../../src/pages/buckmemo/index.tsx" /* webpackChunkName: "component---src-pages-buckmemo-index-tsx" */),
  "component---src-pages-buckmemo-privacy-policy-tsx": () => import("./../../../src/pages/buckmemo/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-buckmemo-privacy-policy-tsx" */),
  "component---src-pages-buckmemo-terms-of-use-tsx": () => import("./../../../src/pages/buckmemo/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-buckmemo-terms-of-use-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}


module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/ronaldmak/Documents/Projects/walker-one/src/src/lang","languages":["en","fr","zh-Hans","zh-Hant","de","ja","ko"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Buckmemo","short_name":"Buckmemo","description":"Smart Personal Bookkeeper","lang":"en","start_url":"/en/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"src/images/icon.png","icons":[{"src":"/favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/favicons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"localize":[{"start_url":"/de/","lang":"de","name":"Geschäftsbuch","short_name":"Geschäftsbuch","description":"Persönliches Abrechnungsprogramm"},{"start_url":"/fr/","lang":"fr","name":"Comptabilité","short_name":"Comptabilité","description":"Comptable Personnel Intelligent"},{"start_url":"/ja/","lang":"ja","name":"帳簿","short_name":"帳簿","description":"個人家計簿モバイルアプリ"},{"start_url":"/ko/","lang":"ko","name":"장부","short_name":"장부","description":"똑똑한 개인 부기"},{"start_url":"/zh-Hans/","lang":"zh-Hans","name":"帐簿","short_name":"帐簿","description":"智能记账程式"},{"start_url":"/zh-Hant/","lang":"zh-Hant","name":"數簿","short_name":"數簿","description":"個人記帳程式"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f28e3d916e03fae40797ce31e1db248a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
